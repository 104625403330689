import { httpDelete, httpGet, httpGetToBlob, httpPost, httpPut } from "../utils/httpAPI"
import { ProductExcelParams, ProductType } from "types/ProductType"
import { CountType } from "types/CountType"
import { PageType } from "types/PageType"
import { ProductPriceHistoryType } from "types/ProductPriceHistoryType"
import { SearchKeywordType } from "types/SearchKeywordType"
import { ResourceType } from "../types/ResourceType"
import { PaymentMethodTypeCode, PaymentMethodTypeGroupCode } from "../types/PaymentType"
import { FeePolicyDataType } from "../types/FeePolicyType"

export const createProduct = (product: ProductType): Promise<ProductType> => httpPost("/products", product)
export const getProducts: (params?: { [key: string]: any }) => Promise<PageType<ProductType>> = (params) =>
  httpGet("/products", params)
export const getProductSimpleList: (params?: { [key: string]: any }) => Promise<PageType<ProductType>> = (params) =>
  httpGet("/products/with-disparity", params)
export const deleteProduct: (sid: string) => Promise<string> = (sid) => httpDelete(`/products/${sid}`)
export const getProduct = (sid: ProductType["id"]): Promise<ProductType> => httpGet(`/products/${sid}`)
export const showProduct: (sid: string) => Promise<string> = (sid) => httpPost(`/products/${sid}/show`)
export const hideProduct: (sid: string) => Promise<string> = (sid) => httpPost(`/products/${sid}/hide`)
export const updateProduct = (productId: ProductType["id"], product: Partial<Omit<ProductType, "id">>) =>
  httpPut<ProductType>(`/products/${productId}`, product)

export const reorderProductGallery = (productId: ProductType["id"], resources: ResourceType[]) =>
  httpPost<ProductType>(`/products/${productId}/gallery/reordering`, resources)

export const countProducts: (searchParams: any) => Promise<CountType[]> = (searchParams) =>
  httpGet("/products/counts", searchParams)
export const completeSetup = (sid: ProductType["id"]): Promise<any> => httpPost(`/products/${sid}/complete-setup`)
export const launch: (sids: string[]) => Promise<any> = (sids) => httpPost(`/products/launch`, sids)

export const getProductPriceHistories = (searchParams: any): Promise<PageType<ProductPriceHistoryType>> =>
  httpGet(`/products/${searchParams.productId}/price-histories`, searchParams)

export const getProductSearchKeywords = (): Promise<SearchKeywordType[]> => httpGet(`/products/search-keywords`)

export const postProductSeacrhKeyord = (body: { keyword: string }): Promise<SearchKeywordType> =>
  httpPost(`/products/search-keywords`, body)

export const reOrderProductSearchKeywordPriority = (body: { id: number }[]): Promise<SearchKeywordType[]> =>
  httpPut(`/products/search-keywords/reordering`, body)

export const deleteProductSearchKeyword = (keywordId: number): Promise<void> =>
  httpDelete(`/products/search-keywords/${keywordId}`)
export const completeOnSitePayment = (params: {
  productId: number
  productPrice: number
  advancedDiagnosis: boolean
  diagnosisFee: number
  payments: {
    paymentMethodType: PaymentMethodTypeCode
    paymentAmount: number
    cardCode?: string
    cashReceipt: boolean
  }[]
  isDiagnosisFee: boolean
  buyingFeePolicyType: string
  buyingFee: number
  taxFreeRefund: boolean
}) =>
  httpPost(`/products/${params.productId}/complete-onsite-payment`, {
    amount: params.productPrice,
    advancedDiagnosis: params.advancedDiagnosis,
    diagnosisFee: params.diagnosisFee,
    payments: params.payments,
    diagnosisFeeFree: !params.isDiagnosisFee,
    buyingFeePolicyType: params.buyingFeePolicyType,
    buyingFee: params.buyingFee,
    taxFreeRefund: params.taxFreeRefund,
  })

export const cancelOnSitePayment = (productId: number) => httpPost(`/products/${productId}/refund-onsite-payment`)

export const negotiatePrice = (productId: number, negotiatedAmount: number) => {
  return httpPost(`/products/${productId}/negotiate-final-price`, {
    negotiatedAmount,
  })
}

export const updateUserDescription = (productId: number, userDescription: string): Promise<ProductType> =>
  httpPost(`/products/${productId}/userdescription`, { userDescription })

export const downloadProductExcel = (params?: { [key: string]: any }): Promise<Blob> =>
  httpGetToBlob("/products/excel", params)
