import { ResellPriceResponse } from "types/EstimationType"
import { useCallback, useEffect, useState } from "react"
import {
  Accessories,
  AccessoriesConditionCode,
  DiagnosisResultCode,
  DiagnosisStatusCode,
  DiagnosisType,
  OfficialGuaranteeCode,
  OriginalBoxConditionCode,
  PenaltyReasonTypeCode,
  ProductConditionCode,
} from "types/DiagnosisType"
import { numberWithCommas, precisionRound } from "utils/NumberUtils"
import { toast } from "react-toastify"
import { defaultImageLabels } from "types/ProductType"
import { complete, updateDiagnosis, confirm, getDiagnosis } from "apis/diagnosisAPI"
import { getCancelSellOrderPenaltyFee, getResellPrice, getDisqualifiedSellOrderPenaltyFee } from "apis/estimationAPI"
import { SellOrderType } from "types/SellOrderType"
import { BrandType } from "../../../types/BrandType"
import { getBrand } from "../../../apis/brandAPI"
import { validateEngDate } from "../../../utils/validationUtils"

const initialDiagnosisState = {
  id: 0,
  seller: undefined,
  receivedAt: "",
  sellerId: undefined,
  title: undefined,
  serialNo: undefined,
  status: undefined,
  result: undefined,
  grade: undefined,
  failedReason: undefined,
  penaltyReasonType: undefined,
  purchasedBuyOrder: undefined,
  components: {
    matched: false,
    sellerProvided: {
      productCondition: ProductConditionCode.미착용,
      officialGuarantee: OfficialGuaranteeCode.없음,
      accessoriesCondition: AccessoriesConditionCode.없음,
      originalBoxCondition: OriginalBoxConditionCode.없음,
    },
    staffConfirmed: {
      productCondition: ProductConditionCode.미착용,
      officialGuarantee: OfficialGuaranteeCode.없음,
      accessoriesCondition: AccessoriesConditionCode.없음,
      originalBoxCondition: OriginalBoxConditionCode.없음,
      accessories: {
        outerBox: false,
        innerBox: false,
        coscCert: false,
        userManual: false,
        guaranteeCase: false,
        medal: false,
      },
      onDisplayStampingYear: false,
    },
  },
  appearanceCondition: { parts: [], note: "" },
  internalCondition: undefined,
  images: [],
  upgradeRequired: undefined,
  generalReview: "",
  viverPrices: {},
  userDesiredPrice: 0,
  diagnosedAt: undefined,
  diagnosedBy: undefined,
  confirmedAt: undefined,
  confirmedBy: undefined,
  notifiedAt: undefined,
  notifiedBy: undefined,
  version: 0,
}

type Props = {
  sellOrder: SellOrderType
  isResellPriceNotRequested?: boolean
}

export const useDiagnosisData = ({ sellOrder, isResellPriceNotRequested }: Props) => {
  const [brand, setBrand] = useState<BrandType | undefined>()
  const [diagnosisState, setDiagnosisState] = useState<DiagnosisType>(
    sellOrder?.productDiagnosis || initialDiagnosisState
  )
  const [penalty, setPenalty] = useState<{
    canceled: number
    disqualified: number
  }>({
    canceled: 0,
    disqualified: 0,
  })
  const [estimatedPrice, setEstimatedPrice] = useState<ResellPriceResponse>({})
  const [saving, setSaving] = useState<boolean>(false)
  const [formattedDate, setFormattedDate] = useState("")

  const formatDate = (date: Date) => {
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")
    const year = date.getFullYear()
    return `${day}/${month}/${year}`
  }
  const generateGeneralReview = (diagnosis: DiagnosisType) => {
    let review = ""

    switch (diagnosis?.result) {
      case "합격":
        review += "바이버 전문가의 진단 결과, 판매 가능한 '합격' 상품으로 확인되었습니다.\n"
        break
      case "조건부 합격":
        review +=
          "바이버 전문가의 진단 결과, 판매를 위해서는 반드시 업그레이드가 필요한 '조건부 합격' 상품으로 확인되었습니다.\n"
        break
      case "불합격":
        review +=
          "아쉬운 결과를 전해드려 대단히 죄송합니다. 바이버 전문가의 진단 결과, 판매가 불가능한 '불합격' 상품으로 확인되었습니다.\n"
        break
      default:
        break
    }
    switch (diagnosis?.grade) {
      case "양호":
        review +=
          "전반적으로 모든 상태가 양호하여 간단한 세척과 라이트폴리싱 후 바로 바이버 판매 상품으로 등록이 진행됩니다.\n"
        break
      case "보통":
        review +=
          "전반적으로 사용감은 있지만, 바이버 전문 엔지니어의 부분 폴리싱과 무상 수리를 통해 충분히 상품화가 가능한 상태입니다.\n"
        break
      case "업그레이드 필요":
        review +=
          "시계 본래의 기능인 정확성이 현저히 떨어지거나, 제품 전체의 광택이 저하되어 상품성이 낮아진 경우 해당 제품은 판매가 어렵습니다. 하지만 바이버의 숙련된 엔지니어를 통해 전문적인 오버홀, 폴리싱 작업을 거쳐 최상의 상태로 상품을 업그레이드하여 판매할 수 있습니다.\n"
        break
      case "판매 불가":
        review += `주요 판매 불가 사유는 다음과 같습니다.\n * ${diagnosis?.failedReason}\n`
        break
      default:
        break
    }

    if (diagnosis?.grade === "업그레이드 필요") {
      review += "고객님의 제품에 필요한 업그레이드(수리) 작업은 다음과 같습니다.\n"
      let fee = 0
      diagnosis?.upgradeRequired
        ?.filter((it) => it.required)
        ?.forEach((it) => {
          fee += it.fee
          if (it.note) review += `${it.note}\n`
          else
            switch (it.label) {
              case "폴리싱":
                review += " ＊폴리싱을 통해 시계의 광택을 살리고 스크레치를 개선합니다.\n"
                break
              case "오버홀":
                review +=
                  " ＊오버홀을 통해 시계 속 무브먼트를 완전 분해, 점검, 수리하고 조정하는 작업을 수행, 시계 내부를 최상의 상태로 개선합니다.\n"
                break
            }
        })
      review += `바이버 엔지니어가 산정한 업그레이드 비용은 (${numberWithCommas(fee)}원)입니다.\n`
    }
    if (diagnosis?.grade !== "판매 불가") {
      if (!diagnosis?.components?.matched) {
        review += "패키지 상태 확인 결과, 작성해 주신 패키지 상태와 일부 차이가 있어 안내드립니다.\n"
        review += diagnosis?.components?.mismatchedReason || ""
      }
      review += diagnosis?.components?.note || ""
    }
    review += "\n진단을 통해 확인한 시계의 상세 상태는 내부/외부 검수항목 이미지를 참조해 주세요.\n"
    if (diagnosis?.grade !== "양호") {
      diagnosis?.appearanceCondition?.parts?.forEach((it) => {
        review += ` * ${it.label}: ${it.grade}`
        if (it.note) review += `, ${it.note || ""}`
        review += "\n"
      })
      diagnosis?.internalCondition?.parts?.forEach((it) => {
        review += ` * ${it.label}: ${it.measured || ""} ${it.grade}`
        if (it.note) review += `, ${it.note || ""}`
        review += "\n"
      })
    }
    review += diagnosis?.viverPrices?.afterDiagnosisPrice?.reason || ""
    review += diagnosis?.viverPrices?.afterUpgradePrice?.reason || ""

    const price = Number(diagnosis?.viverPrices?.finalPrice || diagnosis?.userDesiredPrice)
    switch (diagnosis?.penaltyReasonType) {
      case PenaltyReasonTypeCode.가품:
        review += `바이버의 페널티 정책에 따라 예상 판매 금액 평균가 ${numberWithCommas(
          price
        )}원의 10%인 ${numberWithCommas(precisionRound(price * 0.1))}원이 고객님이 등록한 계좌에서 즉시 차감됩니다.\n`
        break
      case PenaltyReasonTypeCode.취급불가_커스텀:
        review += `바이버의 페널티 정책에 따라 예상 판매 금액 평균가 ${numberWithCommas(
          price
        )}원의 1.8%인 ${numberWithCommas(
          precisionRound(price * 0.018)
        )}원이 고객님이 등록한 계좌에서 즉시 차감됩니다.\n`
        break
      case PenaltyReasonTypeCode.부품_교체_필요:
        review +=
          "해당 상품은 정식 매장에서 부품을 교체해야 판매가 가능한 상품으로 별도의 페널티를 부여하지 않고 반송합니다. 부품을 교체하신 후 다시 바이버로 판매를 의뢰해 주세요.\n"
        break
      case PenaltyReasonTypeCode.상품화_불가:
        review +=
          "해당 상품은 정품이지만, 수리를 통한 업그레이드가 불가할 정도로 손상 상태가 심각하여, 바이버의 정책 상 판매가 불가능한 상품으로 판정되었습니다. 이러한 경우, 바이버에서는 별도의 페널티를 부여하지 않고 반송합니다.\n"
        break
    }

    switch (diagnosis?.result) {
      case DiagnosisResultCode.합격:
        review +=
          "진단 결과 확인 후 판매승인 하시면, 상품 세척 등 기본적인 케어와 상품촬영을 진행 한 후, 바이버 스토어에 판매 상품으로 노출됩니다. 상품이 등록되면 알림톡으로 알려드려요.\n"
        break
      case DiagnosisResultCode.조건부_합격:
        review +=
          "진단 결과 확인 후 판매승인 하시면, 바이버 업그레이드 진행 후 판매 상품으로 노출됩니다. 발생한 업그레이드 비용은 상품이 판매되면 최종 정산금액에서 차감됩니다.\n"
        break
      case DiagnosisResultCode.불합격:
        review += "해당 상품은 미납 페널티가 없음이 확인되면 2일 이내(주말,공휴일 제외) 반송 절차가 진행됩니다.\n"
        break
    }

    setDiagnosisState({ ...diagnosis, generalReview: review })
    update({ ...diagnosis, generalReview: review })
  }

  const checkRequired = (): boolean => {
    if (!diagnosisState?.serialNo) {
      toast.error("시리얼 넘버를 입력해주세요")
      return false
    }
    if (!diagnosisState?.components?.staffConfirmed?.productCondition) {
      toast.error("제품상태를 선택해주세요")
      return false
    }
    if (!diagnosisState?.components?.staffConfirmed?.officialGuarantee) {
      toast.error("보증서 유무를 선택해주세요")
      return false
    } else if (diagnosisState?.components?.staffConfirmed?.officialGuarantee === OfficialGuaranteeCode.있음) {
      if (!diagnosisState?.components?.staffConfirmed?.stampingDate) {
        toast.error("스탬핑 연월일을 입력해주세요")
        return false
      }
    }
    if (!diagnosisState?.components?.staffConfirmed?.accessoriesCondition) {
      toast.error("부속품 상태를 선택해주세요")
      return false
    }

    if (!diagnosisState?.components?.staffConfirmed?.originalBoxCondition) {
      toast.error("정품박스 상태를 선택해주세요")
      return false
    }

    if (diagnosisState?.components?.matched === undefined) {
      toast.error("패키지 상태 일치여부를 선택해주세요")
      return false
    } else if (!diagnosisState?.components?.matched && !diagnosisState?.components?.mismatchedReason) {
      toast.error("패키지 상태 불일치 상세설명을 입력해주세요")
      return false
    }

    if (diagnosisState?.appearanceCondition?.parts?.find((it) => !it.grade)) {
      toast.error("외부 컨디션 항목별 상태를 모두 선택해주세요")
      return false
    }
    if (diagnosisState?.internalCondition?.parts?.find((it) => !it.grade)) {
      toast.error("내부 컨디션 항목별 상태를 모두 선택해주세요")
      return false
    }
    if (diagnosisState?.internalCondition?.parts?.find((it) => !it.measured)) {
      toast.error("내부 컨디션 측정값을 모두 입력해주세요")
      return false
    }

    if (diagnosisState?.grade === "판매 불가") {
      if (!diagnosisState?.failedReason) toast.error("판매 불가 사유를 입력해주세요")
      // else if (!diagnosisState?.penaltyReasonType) toast.error("판매 불가 페널티를 선택해주세요")
      else return true

      return false
    }
    if (defaultImageLabels.find((required) => !diagnosisState?.images?.find((it) => required.required && it.url))) {
      toast.error(`필수 이미지를 모두 첨부해주세요`)
      return false
    }

    if ((diagnosisState?.images?.filter((it) => it.onDisplay)?.length || 0) < 2) {
      toast.error("판매자에게 노출할 이미지를 선택해주세요")
      return false
    }
    if (diagnosisState?.status === "진단서 작성 완료") {
      const suggestedPrice =
        (diagnosisState?.result === "합격"
          ? diagnosisState?.viverPrices?.afterDiagnosisPrice?.after
          : diagnosisState.viverPrices?.suggestedPrice) || 0

      if (suggestedPrice - penalty.canceled < 1) {
        toast.error("최종 최적 판매가가 올바르지 않습니다.")
        return false
      }
    }
    if (diagnosisState?.status !== DiagnosisStatusCode.진단서_작성중) {
      if (!diagnosisState?.generalReview?.length) {
        toast.error("진단 총평을 작성해주세요.")
        return false
      }
    }
    return true
  }

  const checkAccessoriesAll = (accessories: Accessories): boolean =>
    !!(
      accessories.outerBox &&
      accessories.innerBox &&
      accessories.coscCert &&
      accessories.userManual &&
      accessories.guaranteeCase &&
      accessories.guaranteeManual &&
      accessories.medal
    )

  const checkAccessoriesNone = (accessories: Accessories): boolean =>
    !(
      accessories.outerBox &&
      accessories.innerBox &&
      accessories.coscCert &&
      accessories.userManual &&
      accessories.guaranteeCase &&
      accessories.guaranteeManual &&
      accessories.medal
    )

  const update = (diagnosis: DiagnosisType) => {
    if (formattedDate && !validateEngDate(formattedDate)) {
      toast.error("스탬핑 일자를 확인해주세요. DD/MM/YYYY 형식으로 입력해야 합니다.")
      return Promise.reject(new Error("Invalid date format"))
    }
    diagnosis.components.staffConfirmed.stampingDate = formattedDate.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$2-$1")
    setSaving(true)

    return updateDiagnosis(diagnosis?.id, diagnosis)
      .then((res) => {
        setDiagnosisState({ ...res, version: res.version + 1 })
        toast.success("저장 완료")
      })
      .catch((error) => {
        toast.error(`저장 실패 (${error.message || error})`)
      })
      .finally(() => setSaving(false))
  }

  const setComplete = async (): Promise<void> => {
    if (!window.confirm("진단서 작성을 완료하시겠습니까?") || !checkRequired()) {
      return
    }

    update(diagnosisState).then(() => {
      setSaving(true)
      complete(diagnosisState.id)
        .then((res) => {
          setDiagnosisState((prev) => ({
            ...prev,
            version: res.version + 1,
            status: DiagnosisStatusCode.진단서_작성_완료,
            diagnosedBy: res.diagnosedBy,
          }))
          toast.success("진단서 작성 완료")
        })
        .catch((error) => {
          toast.error(`진단서 작성완료 처리 실패 (${error.message || error})`)
        })
        .finally(() => setSaving(false))
    })
  }

  const setConfirm = () => {
    if (!diagnosisState) {
      return
    }

    if (window.confirm("진단서를 확정하시겠습니까?") && checkRequired()) {
      setSaving(true)
      confirm([diagnosisState?.id])
        .then((res) => {
          const viverPrices = JSON.parse(JSON.stringify(diagnosisState?.viverPrices))
          viverPrices!.estimatedPriceAtDiagnosis = estimatedPrice?.correctedEstimatedPrice

          setDiagnosisState((prev) => ({
            ...prev,
            status: DiagnosisStatusCode.진단서_확정,
            diagnosedBy: res.diagnosedBy,
            viverPrices: viverPrices,
          }))
          toast.success("진단서 확정 완료")
        })
        .catch((error) => {
          toast.error(`진단서 확정 처리 실패 (${error.message || error})`)
        })
        .finally(() => setSaving(false))
    }
  }

  const requestCurrentResellPrice = useCallback(
    (diagnosis: DiagnosisType) => {
      // 현 시세 사용
      const staffConfirmed = diagnosis.components?.staffConfirmed

      const dto = {
        detailModel: { id: sellOrder?.product?.detailModel?.id! },
        mainProductCondition: staffConfirmed?.productCondition,
        accessoriesCondition: staffConfirmed?.accessoriesCondition,
        officialGuarantee: staffConfirmed?.officialGuarantee,
        stampingYear: staffConfirmed?.stampingDate ? parseInt(staffConfirmed?.stampingDate.substring(0, 4)) : undefined,
        originalBoxCondition: staffConfirmed.originalBoxCondition,
      }
      getResellPrice(dto).then((res) => {
        setEstimatedPrice(res)

        if (diagnosis.status !== DiagnosisStatusCode.진단서_확정) {
          const median = res.correctedEstimatedPrice?.median || diagnosis.userDesiredPrice
          const viverPrices = JSON.parse(JSON.stringify(diagnosis?.viverPrices))

          viverPrices!.afterDiagnosisPrice!.before = median
          viverPrices!.afterDiagnosisPrice!.after = median
            ? median + viverPrices!.afterDiagnosisPrice!.delta
            : viverPrices!.afterDiagnosisPrice!.delta

          viverPrices!.afterUpgradePrice!.before = median
          viverPrices!.afterUpgradePrice!.after = median
            ? median + viverPrices!.afterUpgradePrice!.delta
            : viverPrices!.afterUpgradePrice!.delta

          viverPrices!.suggestedPrice =
            diagnosis?.result === "합격"
              ? viverPrices?.afterDiagnosisPrice?.after
              : viverPrices?.afterUpgradePrice?.after

          setDiagnosisState({ ...diagnosis, viverPrices: viverPrices })
        }
      })
    },
    [sellOrder?.product?.detailModel?.id]
  )

  useEffect(() => {
    if (sellOrder.productDiagnosis) {
      getDiagnosis(sellOrder.productDiagnosis.id).then((diagnosis) => {
        setDiagnosisState({
          ...diagnosis,
          viverPrices: {
            ...diagnosis.viverPrices,
            finalPrice: diagnosis?.viverPrices?.finalPrice || diagnosis.userDesiredPrice,
            suggestedPrice: diagnosis?.viverPrices?.suggestedPrice || diagnosis.userDesiredPrice,
          },
        })
        if (diagnosis.components?.staffConfirmed?.stampingDate) {
          const initialDate = new Date(diagnosis.components.staffConfirmed.stampingDate)
          setFormattedDate(formatDate(initialDate))
        } else {
          setFormattedDate("")
        }
        !isResellPriceNotRequested && requestCurrentResellPrice(diagnosis)
      })
      getBrand(sellOrder?.product?.brandSid || "ETC").then((brand) => {
        setBrand(brand)
      })
    }
    setSaving(false)
  }, [sellOrder.productDiagnosis, isResellPriceNotRequested, requestCurrentResellPrice])

  useEffect(() => {
    if (sellOrder.product?.initialPrice) {
      getCancelSellOrderPenaltyFee({ sellOrder: { id: sellOrder.id } }).then((penalty) => {
        setPenalty((prev) => ({ ...prev, canceled: penalty.paymentAmount }))
      })
    }

    if (diagnosisState.penaltyReasonType) {
      getDisqualifiedSellOrderPenaltyFee({
        sellOrder: { id: sellOrder.id },
        penaltyReasonType: diagnosisState.penaltyReasonType,
      }).then((res) => {
        setPenalty((prev) => ({ ...prev, disqualified: res.paymentAmount }))
      })
    }
  }, [diagnosisState.penaltyReasonType, sellOrder.product?.initialPrice, sellOrder.id])

  const changeDiagnosisState = useCallback((key: keyof DiagnosisType, newState: any) => {
    setDiagnosisState((prev) => ({ ...prev, [key]: newState }))
  }, [])

  const changeDiagnosisStateAndRequestResellPrice = (key: keyof DiagnosisType, newState: any) => {
    changeDiagnosisState(key, newState)
    !isResellPriceNotRequested && requestCurrentResellPrice({ ...diagnosisState, [key]: newState })
  }

  return {
    brand: brand,
    diagnosis: diagnosisState,
    setDiagnosis: setDiagnosisState,
    penalty,
    update,
    estimatedPrice,
    saving,
    generateGeneralReview,
    formattedDate,
    setFormattedDate,
    checkAccessoriesAll,
    checkAccessoriesNone,
    setComplete,
    setConfirm,
    changeDiagnosisState,
    changeDiagnosisStateAndRequestResellPrice,
  }
}
