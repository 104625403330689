import {DataGrid, GridColumnHeaderParams} from '@mui/x-data-grid';
import React from "react";
import {SellOrderType} from "types/SellOrderType";
import {DateTime} from "luxon";

function createColumns(): any[] {
    return [
        {
            field: 'id',
            editable: false,
            sortable: false,
            minWidth: 140,
            headerName: "주문번호",
            renderCell: (params: any) => (
                <div style={{textAlign: "left"}}>
                    <p>{params.row.id}</p>
                </div>
            )
        },
        {
            field: 'sellOrderStatus',
            editable: false,
            sortable: false,
            minWidth: 75,
            maxWidth: 75,
            headerName: "주문상태",
            renderCell: (params: any) => (
                <div style={{textAlign: "center"}}>
                    <p>{params.row.sellOrderStatus}</p>
                </div>
            )
        },
        {
            field: 'acceptedAt',
            headerName: "판매신청일",
            editable: false,
            sortable: false,
            minWidth: 80,
            maxWidth: 80,
            renderHeader: (params: GridColumnHeaderParams) => (<p style={{lineHeight:1}}>판매<br/>신청일</p>),
            valueGetter: (params: any) => params.value ? DateTime.fromISO(params.value)?.toFormat('yyyy-MM-dd HH:mm:ss') : '',
            renderCell: (params: any) => (
                <div style={{textAlign: "center"}}>
                    <p>{params.row.acceptedAt ? DateTime.fromISO(params.row.acceptedAt)?.toFormat('yy-MM-dd') : '-'}<br/>
                        {params.row.acceptedAt ? DateTime.fromISO(params.row.acceptedAt)?.toFormat('HH:mm:ss') : ''}</p>
                </div>
            )
        },
        {
            field: 'waitingDiagnosisAt',
            headerName: "입고확인일",
            editable: false,
            sortable: false,
            minWidth: 80,
            maxWidth: 80,
            renderHeader: (params: GridColumnHeaderParams) => (<p style={{lineHeight:1}}>입고<br/>확인일</p>),
            valueGetter: (params: any) => params.value ? DateTime.fromISO(params.value)?.toFormat('yyyy-MM-dd HH:mm:ss') : '',
            renderCell: (params: any) => (
                <div style={{textAlign: "center"}}>
                    <p>{params.row.waitingDiagnosisAt ? DateTime.fromISO(params.row.waitingDiagnosisAt)?.toFormat('yy-MM-dd') : '-'}<br/>
                        {params.row.waitingDiagnosisAt ? DateTime.fromISO(params.row.waitingDiagnosisAt)?.toFormat('HH:mm:ss') : ''}</p>
                </div>
            )
        },
        {
            field: 'sellerName',
            headerName: "판매자",
            editable: false,
            sortable: false,
            minWidth: 70,
            maxWidth: 70,
            valueGetter: (params: any) => `${params.row.seller?.name || '-'}`,
        },
        {
            field: 'phone',
            headerName: "전화번호",
            editable: false,
            sortable: false,
            minWidth: 110,
            maxWidth: 110,
            valueGetter: (params: any) => `${params.row.seller?.phone || '-'}`,
        },
        {
            field: 'product.title',
            headerName: "상품명",
            editable: false,
            sortable: false,
            minWidth: 200,
            maxWidth: 200,
            valueGetter: (params: any) => `${params.row.product?.title}`,
        },
    ]
}

type SellOrderProps = {
    orders: SellOrderType[],
    selectedIds: string[],
    handleSelection: (ids: any[]) => void,
    close: Function,
}

const ConfirmReceivingTable: React.FC<SellOrderProps> = ({orders, selectedIds, handleSelection, close}) => {

    return (
        <>
            <div style={{height: '80vh', width: '100%'}}>
                <DataGrid
                    columns={createColumns()}
                    rows={orders}
                    getRowId={row => row.id!}

                    checkboxSelection={false}
                    disableSelectionOnClick={false}
                    selectionModel={selectedIds}
                    onSelectionModelChange={handleSelection}
                />
            </div>
        </>)
}

// @ts-ignore
export default ConfirmReceivingTable