import { httpGet, httpPost, httpPut } from "../utils/httpAPI"
import { AskingPriceType } from "types/AskingPriceType"
import { PageType } from "types/PageType"

export const getAskingPrices: (searchParams: any) => Promise<PageType<AskingPriceType>> = (searchParams) =>
  httpGet("/metrics/asking-prices", searchParams)
export const getAskingPriceList: (searchParams: any) => Promise<AskingPriceType[]> = (searchParams) =>
  httpGet("/metrics/asking-prices/list", searchParams)
export const updateAskingPrice: (askingPrice: AskingPriceType) => Promise<AskingPriceType> = (askingPrice) =>
  httpPut(`/metrics/asking-prices`, askingPrice)
export const aggregateAskingPrice: (req: any) => Promise<any> = (req) =>
  httpPost(`/metrics/asking-prices/aggregate`, req)
