import * as Icons from "@mui/icons-material"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import {
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  styled,
  Theme,
  Toolbar,
  useTheme,
} from "@mui/material"
import { FunctionComponent, useContext, useState } from "react"
import { useNavigate } from "react-router-dom"

import { getAside } from "../../authRouteInfo"
import Link from "@mui/material/Link"
import { getVersion } from "../../utils/versionUtils"
import { AuthContext } from "../../AuthContext"

interface PropsType {
  asideWidth: number
  isOpenAside: boolean
  toggleIsOpenAside: () => void
}
const LayoutNavigationAside: FunctionComponent<PropsType> = ({ asideWidth, isOpenAside, toggleIsOpenAside }) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const { user } = useContext(AuthContext)

  const [isOpenGroup, setIsOpenGroup] = useState<{ [key: string]: boolean }>({
    대시보드: false,
    "판매 상품 관리": false,
    "구매 관리": false,
    "콘텐츠 관리": false,
    "DB 관리": false,
  })
  const toggleIsOpenGroup = (title: string) => {
    // @ts-ignore
    setIsOpenGroup((isOpenGroup) => {
      return { ...isOpenGroup, [title]: !isOpenGroup[title] }
    })
  }

  return (
    <StyledDrawer variant="permanent" theme={theme} asideWidth={asideWidth} isOpenAside={isOpenAside}>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          px: [1],
        }}
      >
        <Link href={"/"}>
          <img src={`${process.env.PUBLIC_URL}/viver.png`} style={{ maxWidth: "100%" }} alt="logo icon" />
        </Link>
        <IconButton onClick={toggleIsOpenAside}>
          <Icons.ChevronLeft />
        </IconButton>
      </Toolbar>
      <Divider />
      <List component="nav">
        {getAside(user?.menus ?? [])
          .map(({ title, children }, index) => (
            <div key={`${title}_${index}`}>
              <ListItemButton onClick={() => toggleIsOpenGroup(title)}>
                <ListItemText primary={title} />
                {isOpenAside && (isOpenGroup[title] ? <ExpandLess /> : <ExpandMore />)}
              </ListItemButton>
              <Collapse in={isOpenGroup[title]} timeout="auto" unmountOnExit>
                <List>
                  {children.map(({ path, title }) => (
                    <ListItemButton key={title} sx={{ pl: 4 }} onClick={() => navigate(path)}>
                      <ListItemText primary={title} />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </div>
          ))}
        <ListItemButton>
          <ListItemText primary={'Admin Version'} secondary={`v${getVersion()}`} />
        </ListItemButton>
      </List>
    </StyledDrawer>
  )
}
export default LayoutNavigationAside

const StyledDrawer = styled(Drawer)<{
  theme: Theme
  asideWidth: number
  isOpenAside: boolean
}>(({ theme, asideWidth, isOpenAside }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: asideWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!isOpenAside && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(0),
      },
    }),
  },
}))
