import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import ConfirmReceivingTable from "./ConfirmReceivingTable";
import {SellOrderStatusCode, SellOrderType} from "types/SellOrderType";
import { confirmReceiving, getSellOrder } from "apis/sellOrderAPI"
import {useReactToPrint} from "react-to-print";
import QRTableToPrint from "../../../../components/Popup/ProductQrPopup/QRTableToPrint";
import { isEmpty } from "lodash";

type ConfirmReceivingPopupProps = {
    open: boolean,
    initOrders: SellOrderType[],
    handleClose: Function,
}

const ConfirmReceivingPopup: React.FC<ConfirmReceivingPopupProps> = ({open, initOrders, handleClose}) => {

    const [orders, setOrders] = useState<SellOrderType[]>(initOrders)
    const [selectedIds, setSelectedIds] = useState<string[]>([])
    const [selectedOrder, setSelectedOrder] = useState<SellOrderType>()

    const handleSelection = (ids: (string|number)[]) => {
        if (isEmpty (ids)) {
            return
        }

        setSelectedIds(ids as string[])
        getSellOrder(ids[0].toString()).then((result)=>{
            setSelectedOrder(result)
        })
    }

    const handleConfirmClick = () => {
        confirmReceiving([selectedOrder?.id!!]).then(res => {
            setOrders(
                orders.map(it => {
                    if (it.id === selectedOrder?.id) {
                        // @ts-ignore
                        it.sellOrderStatus = res?.[0]?.sellOrderStatus
                        // @ts-ignore
                        it.waitingDiagnosisAt = res?.[0]?.waitingDiagnosisAt
                        setSelectedOrder({...selectedOrder, sellOrderStatus: it.sellOrderStatus, waitingDiagnosisAt: it.waitingDiagnosisAt})
                    }
                    return it
                })
            )
        })
    }
    const handlePrintClick = useReactToPrint({
        // @ts-ignore
        content: () => componentRef.current,
        copyStyles: true,
    });

    const componentRef = useRef()

    useEffect(() => {
        setOrders(initOrders)
        setSelectedIds([])
        setSelectedOrder(undefined)
    }, [initOrders])

    return <Dialog open={open} maxWidth={"xl"} fullWidth>
        <DialogTitle>입고상품 확인 및 QR 생성</DialogTitle>
        <DialogContent>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <ConfirmReceivingTable
                        orders={orders}
                        selectedIds={selectedIds}
                        handleSelection={handleSelection}
                    />
                </Grid>
                <Grid item xs={6} sx={{alignSelf: 'center' }} >
                    {selectedOrder ?
                        <>
                            {/*@ts-ignore*/}
                            <div ref={componentRef}>
                                <QRTableToPrint productId={selectedOrder.product?.id}/>
                            </div>
                            <Grid textAlign={"right"}>
                            <Button disabled={selectedOrder?.sellOrderStatus !== SellOrderStatusCode.입고중} variant={"contained"} color={"error"} size={"medium"} sx={{margin: '10px 0 0 10px'}} onClick={handleConfirmClick}>입고확인</Button>
                            <Button disabled={selectedOrder?.sellOrderStatus === SellOrderStatusCode.픽업_준비 || selectedOrder?.sellOrderStatus === SellOrderStatusCode.입고중} variant={"contained"} size={"medium"} sx={{margin: '10px 0 0 10px'}} onClick={handlePrintClick}>QR 출력</Button>
                            </Grid>
                        </>
                        : <Typography textAlign={'center'}>좌측 목록에서 주문을 선택해주세요</Typography>
                    }
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button color={"error"} onClick={() => handleClose()}>닫기</Button>
        </DialogActions>
    </Dialog>
}

export default ConfirmReceivingPopup
