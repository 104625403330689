import { AttachmentType, defaultImageLabels } from "types/DiagnosisType"
import React, { useEffect, useState } from "react"
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material"
import { toast } from "react-toastify"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import ImageUploadButton from "../../components/ImageUploadButton"
import { upload } from "../../apis/resourceAPI"
import { ShowroomPageActionCode } from "../ShowroomManager/showroomPageActionCode"
import { Help } from "@mui/icons-material"
import ListTable from "../../components/Table/List"
import TableBody from "@mui/material/TableBody"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import { numberWithCommas } from "../../utils/NumberUtils"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableContainer from "@mui/material/TableContainer"

export declare interface ImageLabelType {
  label: string
  required: boolean
  uploadName: string
  onDisplaySelectable?: boolean
}

interface AttachmentImageListProps {
  init?: AttachmentType[]
  defaultImageLabels: ImageLabelType[]
  max: number
  save: Function
  readOnly?: boolean
  checkInput?: boolean
  disabled?: boolean
}

const AttachmentImageList: React.FC<AttachmentImageListProps> = ({
  init,
  defaultImageLabels,
  max,
  save,
  checkInput = true,
  readOnly = false,
  disabled = false,
}) => {
  const [defaultSlot, setDefaultSlot] = useState<AttachmentType[]>(init || [])
  const [additionalSlot, setAdditionalSlot] = useState<AttachmentType[]>([])
  const [isShowImageTooltip, setIsShowImageTooltip] = useState<boolean>(false)

  useEffect(() => {
    setDefaultSlot(init?.filter((it) => defaultImageLabels.findIndex((d) => d.label === it.label) > -1) || [])
    setAdditionalSlot(init?.filter((it) => defaultImageLabels.findIndex((d) => d.label === it.label) === -1) || [])
  }, [init])

  const handleAdd = async (files: FileList, label?: string) => {
    await upload(files).then((result) => {
      const newElement = {
        id: result.id!!,
        url: result.url,
        type: result.resourceContentType || "IMG",
        label: label || "추가",
        required: defaultImageLabels.find((it) => it.label == label)?.required,
        onDisplay: label !== "시리얼 넘버",
      }

      if (defaultImageLabels.findIndex((d) => d.label === newElement.label) > -1) {
        // setDefaultSlot((prev) => {
        //   const idx = prev.findIndex((it) => it.label === label);
        //   const updatedSlot = idx > -1 ? [...prev.slice(0, idx), newElement, ...prev.slice(idx + 1)] : [...prev, newElement];
        //   save(updatedSlot.concat(additionalSlot));
        //   return updatedSlot;
        // });

        setDefaultSlot((prev) => {
          const idx = prev.findIndex((it) => it.label == label)
          if (idx > -1) prev.splice(idx, 1)
          // @ts-ignore
          const updatedSlot: AttachmentType[] = [...prev, newElement]

          save(updatedSlot.concat(additionalSlot))
          return updatedSlot
        })
      } else {
        setAdditionalSlot((prev) => {
          const idx = prev.findIndex((it) => it.label == label)
          if (idx > -1) prev.splice(idx, 1)

          // @ts-ignore
          const updatedSlot: AttachmentType[] = fillUp([...prev, newElement])

          save(defaultSlot.concat(updatedSlot))
          return updatedSlot
        })
      }
    })
  }
  const handleDelete = (id?: number) => {
    let idx = defaultSlot.findIndex((it) => it.id == id)
    if (idx > -1) {
      defaultSlot.splice(
        defaultSlot.findIndex((it) => it.id == id),
        1
      )
      setDefaultSlot(JSON.parse(JSON.stringify(defaultSlot)))
    }

    idx = additionalSlot.findIndex((it) => it.id == id)
    if (idx > -1) {
      // 삭제
      additionalSlot.splice(
        additionalSlot.findIndex((it) => it.id == id),
        1
      )
      // 재정렬
      const newOptionalSlot = fillUp(additionalSlot)
      setAdditionalSlot(JSON.parse(JSON.stringify(newOptionalSlot)))
    }
    save(defaultSlot.concat(fillUp(additionalSlot)))
  }

  const handleCheck = (event: any, attachment?: AttachmentType) => {
    if (attachment) {
      setDefaultSlot(
        defaultSlot.map((it) => {
          if (attachment.id == it.id) it.onDisplay = event.target.checked
          return it
        })
      )

      setAdditionalSlot(
        additionalSlot.map((it) => {
          if (attachment.id == it.id) it.onDisplay = event.target.checked
          return it
        })
      )
    }
  }

  const addAttachment = () => {
    if (defaultSlot.length + additionalSlot.length >= max) {
      toast.error(`최대 이미지 개수는 ${max}개 입니다`)
      return
    }
    const newElement = {
      id: 0,
      onDisplay: false,
      label:
        additionalSlot.findIndex((it) => it.label == `추가${additionalSlot.length + 1}`) > -1
          ? `추가${additionalSlot.length + 2}`
          : `추가${additionalSlot.length + 1}`,
    }
    // @ts-ignore
    setAdditionalSlot([...additionalSlot, newElement])
  }

  const fillUp = (slot: AttachmentType[]): AttachmentType[] => {
    return slot
      .filter((it) => it.id)
      .map((a, i) => {
        a.label = `추가${i + 1}`
        return a
      })
  }

  const move = (i: number, delta: number) => {
    if (delta == 1) {
      if (i < additionalSlot.length - 1) {
        let tmp = additionalSlot[i]
        additionalSlot[i] = additionalSlot[i + 1]
        additionalSlot[i + 1] = tmp
      }
    } else if (delta == -1) {
      if (i > 0) {
        let tmp = additionalSlot[i]
        additionalSlot[i] = additionalSlot[i - 1]
        additionalSlot[i - 1] = tmp
      }
    }
    setAdditionalSlot(fillUp(additionalSlot))
    save(defaultSlot.concat(fillUp(additionalSlot)))
  }

  const findByLabel = (label: string) => defaultSlot?.find((it) => it.label === label)
  const showImageUploadGuide = () => {
    setIsShowImageTooltip((prevState) => !prevState)
  }

  return (
    <>
      <div style={{ display: "flex", justifyContent: "start", alignItems: "center", gap: 8 }}>
        <label
          style={{
            margin: "0 0 8px 0",
            gap: 6,
            display: "flex",
            justifyContent: "start",
            alignItems: "end",
          }}
          htmlFor={`contained-button-file-all`}
        >
          <input
            style={{ display: "none" }}
            accept="image/*|video/mp4"
            id="contained-button-file-all"
            multiple
            type="file"
            onChange={async (params) => {
              if (params?.target?.files) {
                const filesArray = Array.from(params.target.files)

                const validNames = ["0", "1", "2", "3", "4", "5", "6", "7"]

                // 파일명 검증
                const hasInvalidFile = filesArray.some((file) => {
                  const nameWithoutExtension = file.name.substring(0, file.name.lastIndexOf("."))
                  if (!validNames.includes(nameWithoutExtension)) {
                    toast.error("파일명을 확인해주세요")
                    return true
                  }
                  return false
                })

                if (hasInvalidFile) return

                // 파일 처리 함수
                const processFile = async (file: File, label: string) => {
                  const dataTransfer = new DataTransfer()
                  dataTransfer.items.add(file)
                  console.log(dataTransfer.files)
                  await handleAdd(dataTransfer.files, label)
                }

                // 파일 처리
                for (const file of filesArray) {
                  const nameWithoutExtension = file.name.substring(0, file.name.lastIndexOf("."))

                  switch (nameWithoutExtension) {
                    case "0":
                      await processFile(file, "시리얼 넘버")
                      break
                    case "1":
                      await processFile(file, "전면")
                      break
                    case "2":
                      await processFile(file, "측면(크라운)")
                      break
                    case "3":
                      await processFile(file, "후면(클라스프)")
                      break
                    case "4":
                      await processFile(file, "측면(크라운 반대편)")
                      break
                    case "5":
                      await processFile(file, "후면(뒷백)")
                      break
                    case "6":
                      await processFile(file, "후면(전체)")
                      break
                    case "7":
                      await processFile(file, "풀패키지샷")
                      break
                    default:
                      break
                  }
                }
              }
            }}
          />
          <Button variant={"contained"} size={"medium"} component="span" color={"success"} style={{ width: "180px" }}>
            이미지 일괄 업로드
          </Button>
        </label>
        <div>
          <label
            style={{ width: "200px", fontSize: 14, textDecoration: "underline", cursor: "pointer" }}
            onClick={showImageUploadGuide}
          >
            일괄 업로드 파일명 설정 가이드
          </label>
          <Dialog open={isShowImageTooltip} fullWidth onClose={showImageUploadGuide}>
            <DialogTitle>파일명 설정 가이드</DialogTitle>
            <DialogContent>
              <TableContainer style={{ maxHeight: 600, minHeight: 200 }}>
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">영역명</TableCell>
                      <TableCell align="center">파일명</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {defaultImageLabels.map((item, index) => {
                      return (
                        <TableRow>
                          <TableCell align={"center"}>{item.label}</TableCell>
                          <TableCell align={"center"}>{item.uploadName}</TableCell>
                        </TableRow>
                      )
                    })}
                    {/* {resellItems?.map((row) => (
                      <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          {row.refNo}
                        </TableCell>
                        <TableCell align="right">{row.year}</TableCell>
                        <TableCell align="right">{row.itemStatus}</TableCell>
                        <TableCell align="right">{numberWithCommas(row.lastPrice)}</TableCell>
                        <TableCell align="right">{row.registerDate}</TableCell>
                      </TableRow>
                    ))}*/}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button size={"small"} variant={"contained"} onClick={showImageUploadGuide}>
                닫기
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>

      <Grid container className={"ta-center"}>
        {defaultImageLabels?.map((imageLabel, idx) => (
          <Grid item xs={2} sx={{ border: "1px solid #aaa", overflow: "auto" }}>
            <Typography fontWeight={"bold"}>
              {imageLabel.label}
              {imageLabel.required ? " *" : ""}
              {imageLabel.label === "전면" ? " (대표 사진)" : ""}
            </Typography>
            {checkInput && (
              <FormControlLabel
                control={<Checkbox />}
                label="노출"
                disabled={readOnly || imageLabel.onDisplaySelectable == false}
                checked={findByLabel(imageLabel.label)?.onDisplay == true}
                onChange={(event) => handleCheck(event, findByLabel(imageLabel.label))}
              />
            )}
            {readOnly ? (
              findByLabel(imageLabel.label)?.url ? (
                <img src={findByLabel(imageLabel.label)?.url} alt={imageLabel.label} height={"132px"} />
              ) : (
                <></>
              )
            ) : (
              <ImageUploadButton
                height={"132px"}
                imageUrl={findByLabel(imageLabel.label)?.url}
                handleImage={(files) => handleAdd(files, imageLabel.label)}
                handleDeleteImage={() => handleDelete(findByLabel(imageLabel.label)?.id)}
              />
            )}
          </Grid>
        ))}
        {additionalSlot?.map((it, i) => (
          <Grid item xs={2} sx={{ border: "1px solid #aaa", overflow: "auto" }}>
            <Typography fontWeight={"bold"}>{it.label}</Typography>
            {checkInput && (
              <FormControlLabel
                control={<Checkbox />}
                label="노출"
                checked={it.onDisplay}
                onChange={(event) => handleCheck(event, it)}
              />
            )}
            {readOnly ? (
              <img src={it.url} alt={it.label} height={"132px"} />
            ) : (
              <ImageUploadButton
                height={"132px"}
                imageUrl={it.url}
                handleImage={(files) => handleAdd(files, it.label)}
                handleDeleteImage={() => handleDelete(it.id)}
              />
            )}
            <Button onClick={() => move(i, -1)}>⇦</Button>
            <Button onClick={() => move(i, 1)}>⇨</Button>
          </Grid>
        ))}
      </Grid>
      <div style={{ textAlign: "right" }}>
        {readOnly || (
          <Button size={"medium"} variant={"outlined"} startIcon={<AddCircleIcon />} onClick={addAttachment}>
            추가
          </Button>
        )}
      </div>
    </>
  )
}

export default AttachmentImageList
