import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import React, {useEffect, useReducer} from "react"
import ProductTable from "./ProductTable"
import {PageType} from "types/PageType"
import {useSearchParams} from "react-router-dom"
import {downloadProductExcel, getProducts} from "apis/productAPI"
import {ProductListSearchParams, ProductStatusCode, ProductType, SaleTypeCode} from "../../types/ProductType";
import EditPreProductPopup from "../PreProduct/EditPreProductPopup";
import ViewConsignmentSellOrderPopup from "../SellOrder/Consignment/ViewConsignmentSellOrderPopup";
import ConfirmBeforeDiagnosisSellOrderPopup from "../SellOrder/BeforeDiagnosis/ConfirmBeforeDiagnosisSellOrderPopup";
import {BuyOrderType} from "../../types/BuyOrderType";
import {OrderType, SellOrderType} from "../../types/SellOrderType";
import ViewBuyOrderPopup from "../BuyOrder/ViewBuyOrderPopup";
import BeforeDiagnosisProductPopup from "../Product/BeforeDiagnosisProductPopup";
import ProductQrPopup from "../../components/Popup/ProductQrPopup";
import { DateTime } from "luxon"
import { DesktopDatePicker } from "@mui/lab"
import {toast} from "react-toastify";


export interface ProductState {
  selectedProduct?: ProductType
  selectedSellOrder?: SellOrderType
  selectedBuyOrder?: BuyOrderType
  selectedIds: string[]
  products: PageType<ProductType>
  onDisplay: Boolean | null
  saleType: SaleTypeCode[]
  startDateFilter?: DateTime
  endDateFilter?: DateTime
  status: ProductStatusCode[]
  searchFilterKey?: keyof ProductListSearchParams
  searchFilterValue?: string | null
  isShowQrPrintPopup: boolean
  isShowProductPopup: boolean
  isShowSellOrderPopup: boolean
  isShowBuyOrderPopup: boolean
  refresh: boolean
  loading: boolean
}

const initState: (productId?: string | null) => ProductState = (productId) => {
  let state: ProductState = {
    selectedProduct: undefined,
    selectedSellOrder: undefined,
    selectedBuyOrder: undefined,
    selectedIds: [],
    products: {
      content: [],
      pageable: { pageSize: 25, pageNumber: 0 },
      totalPages: 0,
      totalElements: 0,
      numberOfElements: 0,
    },
    onDisplay: null,
    saleType: [SaleTypeCode.전시용, SaleTypeCode.직접판매, SaleTypeCode.위탁판매, SaleTypeCode.진단전판매],
    startDateFilter: undefined,
    endDateFilter: undefined,
    status: [ProductStatusCode.판매_취소, ProductStatusCode.판매_대기, ProductStatusCode.판매중, ProductStatusCode.결제_대기, ProductStatusCode.결제_완료, ProductStatusCode.판매_완료],
    searchFilterKey: "id",
    searchFilterValue: undefined,
    isShowQrPrintPopup: false,
    isShowProductPopup: false,
    isShowSellOrderPopup: false,
    isShowBuyOrderPopup: false,
    refresh: false,
    loading: false,
  }

  if (productId) {
    state.searchFilterKey = "id"
    state.searchFilterValue = productId
  }

  return state
}

interface ProductAction {
  type: string
  payload?: any
}

function reducer(state: ProductState, action: ProductAction): ProductState {
  switch (action.type) {
    case "SELECTION_PRODUCT_IDS":
      return { ...state, selectedIds: action.payload }
    case "FETCH_PRODUCT":
      return { ...state, products: action.payload }
    case "REFRESH":
      return { ...state, refresh: !state.refresh }
    case "START_LOADING":
      return { ...state, loading: true }
    case "END_LOADING":
      return { ...state, loading: false }
    case "CHANGE_ON_DISPLAY":
      return { ...state, onDisplay: action.payload }
    case "CHANGE_SALE_TYPE":
      return { ...state, saleType: action.payload }
    case "CHANGE_PRODUCT_STATUS":
      return { ...state, status: action.payload }
    case "CHANGE_SEARCH_FILTER_KEY":
      return { ...state, searchFilterKey: action.payload, searchFilterValue: null }
    case "CHANGE_SEARCH_FILTER_VALUE":
      return { ...state, searchFilterValue: action.payload }
    case "CHANGE_START_DATE":
      return { ...state, startDateFilter: action.payload }
    case "CHANGE_END_DATE":
      return { ...state, endDateFilter: action.payload }
    case "TOGGLE_QR_PRINT_POPUP":
      return { ...state, selectedProduct: action.payload, isShowQrPrintPopup: !state.isShowQrPrintPopup }
    case "TOGGLE_PRODUCT_POPUP":
      return { ...state, selectedProduct: action.payload, isShowProductPopup: !state.isShowProductPopup }
    case "TOGGLE_SELL_ORDER_POPUP":
      return { ...state, selectedSellOrder: action.payload, isShowSellOrderPopup: !state.isShowSellOrderPopup }
    case "TOGGLE_BUY_ORDER_POPUP":
      return { ...state, selectedBuyOrder: action.payload, isShowBuyOrderPopup: !state.isShowBuyOrderPopup }
  }

  return state
}

const AllProducts = () => {
  const [currentSearchParams] = useSearchParams()
  const [state, dispatch] = useReducer(reducer, initState(currentSearchParams.get("product") || currentSearchParams.get("id")))

  const fetchProducts = (searchParams: ProductListSearchParams = {}) => {
    let newParams: ProductListSearchParams = {
      ...searchParams,
      status: state.status,
      saleType: state.saleType,
      startDate: state.startDateFilter && state.startDateFilter.toISODate(),
      endDate: state.endDateFilter && state.endDateFilter.toISODate(),
  }

    if (state.searchFilterKey && state.searchFilterValue)
      newParams = { ...newParams, [state.searchFilterKey]: state.searchFilterValue }
    if (state.onDisplay)
      newParams = { ...newParams, onDisplay: state.onDisplay }

    dispatch({ type: "START_LOADING" })
    getProducts(newParams)
      .then((products) => dispatch({ type: "FETCH_PRODUCT", payload: products }))
      .finally(() => dispatch({ type: "END_LOADING" }))
  }

  const handleExcelDownloadClick = (params: any = {}) => {
    if (state.startDateFilter === undefined) {
      toast.error("시작일을 선택해주세요.")
      return false
    }
    if (state.endDateFilter === undefined) {
      toast.error("종료일을 선택해주세요.")
      return false
    }

    let newParams: ProductListSearchParams = {
      status: state.status,
      saleType: state.saleType,
      startDate: state.startDateFilter && state.startDateFilter.toISODate(),
      endDate: state.endDateFilter && state.endDateFilter.toISODate(),
    }

    if (state.searchFilterKey && state.searchFilterValue)
      newParams = { ...newParams, [state.searchFilterKey]: state.searchFilterValue }
    if (state.onDisplay)
      newParams = { ...newParams, onDisplay: state.onDisplay }

    downloadProductExcel(newParams).then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `상품관리_전체상품.xlsx`);
      document.body.appendChild(link);
      link.click();
    })
  }

  useEffect(() => {
    fetchProducts({
      page: state.products.pageable?.pageNumber || 0,
      size: state.products.pageable?.pageSize || 25,
    })
  }, [state.refresh, state.onDisplay, state.saleType, state.status, currentSearchParams])

  return (
    <Grid container spacing={2}>
      <ProductQrPopup
          open={state.isShowQrPrintPopup}
          productId={state.selectedProduct?.id}
          handleClose={() => {
            dispatch({ type: "TOGGLE_QR_PRINT_POPUP" })
          }}
        />
      {
        state.selectedProduct?.saleType == SaleTypeCode.진단전판매
          ? <BeforeDiagnosisProductPopup
              open={state.isShowProductPopup}
              selectedProductId={state.selectedProduct?.id}
              handleClose={() => {
                dispatch({ type: "TOGGLE_PRODUCT_POPUP" })
                dispatch({ type: "REFRESH" })
              }}
          />
          : <EditPreProductPopup
              open={state.isShowProductPopup}
              selectedProductId={state.selectedProduct?.id}
              handleClose={() => {
                dispatch({ type: "TOGGLE_PRODUCT_POPUP" })
                dispatch({ type: "REFRESH" })
              }}
          />
      }

      {
        state.selectedSellOrder?.sellOrderType == OrderType.진단전 ?
        <ConfirmBeforeDiagnosisSellOrderPopup
          open={state.isShowSellOrderPopup}
          selectedSellOrderId={state.selectedSellOrder?.id}
          handleClose={() => {
            dispatch({ type: "TOGGLE_SELL_ORDER_POPUP" })
            dispatch({ type: "REFRESH" })
          }}
        /> :
        <ViewConsignmentSellOrderPopup
          open={state.isShowSellOrderPopup}
          selectedSellOrderId={state.selectedSellOrder?.id}
          handleClose={() => {
            dispatch({ type: "TOGGLE_SELL_ORDER_POPUP" })
            dispatch({ type: "REFRESH" })
          }}
        />
      }
      <ViewBuyOrderPopup
          open={state.isShowBuyOrderPopup}
          selectedBuyOrderId={state.selectedBuyOrder?.id}
          handleClose={() => {
            dispatch({ type: "TOGGLE_BUY_ORDER_POPUP" })
            dispatch({ type: "REFRESH" })
          }}
      />

      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "row", alignItems: "center" }}>
          <Typography style={{ marginRight: '30px', fontSize: "20px", fontWeight: 700 }}>상품 타입</Typography>
            {[SaleTypeCode.진단전판매, SaleTypeCode.위탁판매, SaleTypeCode.직접판매, SaleTypeCode.전시용]
                .map(it =>
                    <FormControlLabel label={it} control={
                      <Checkbox checked={state.saleType.indexOf(it) > -1}
                                onChange={(e) => {dispatch({ type: "CHANGE_SALE_TYPE", payload: e.target.checked ? state.saleType.concat(it) : state.saleType.filter(code => code != it)})}}/>
                    }/>
                )}
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "row", alignItems: "center" }}>
          <Typography style={{ marginRight: '30px', fontSize: "20px", fontWeight: 700 }}>상품 상태</Typography>
            {[ProductStatusCode.판매_취소, ProductStatusCode.판매_대기, ProductStatusCode.판매중, ProductStatusCode.결제_대기, ProductStatusCode.결제_완료, ProductStatusCode.판매_완료]
              .map(it =>
                <FormControlLabel label={it} control={
                  <Checkbox checked={state.status.indexOf(it) > -1}
                    onChange={(e) => {dispatch({ type: "CHANGE_PRODUCT_STATUS", payload: e.target.checked ? state.status.concat(it) : state.status.filter(code => code != it)})}}/>
                }/>
            )}
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", width: "100%" }}>
          <Grid item xs={12} container spacing={1}>
            <Grid item container xs={12} justifyContent="flex-end" spacing={0}>
              <FormControlLabel label={'노출중인 상품만 보기'} control={<Checkbox checked={state.onDisplay == true} onChange={(e) => {dispatch({ type: "CHANGE_ON_DISPLAY", payload: e.target.checked ? true : undefined})}}/>}/>
              <FormControl sx={{ width: "160px" }} size={"small"}>
                <InputLabel id="search_key">검색항목</InputLabel>
                <Select
                  value={state.searchFilterKey || ""}
                  onChange={(params) => {
                    dispatch({ type: "CHANGE_SEARCH_FILTER_KEY", payload: params.target.value })
                    dispatch({ type: "REFRESH" })
                  }}
                >
                  <MenuItem value={"id"}>상품번호</MenuItem>
                  <MenuItem value={"fullRefNo"}>Full Ref No.</MenuItem>
                  <MenuItem value={"title"}>상품명</MenuItem>가
                  <MenuItem value={"sellOrderId"}>판매주문번호</MenuItem>
                  <MenuItem value={"sellerId"}>판매자 ID</MenuItem>
                  <MenuItem value={"sellerName"}>판매자 이름</MenuItem>
                  <MenuItem value={"sellerPhone"}>판매자 연락처</MenuItem>
                  <MenuItem value={"buyOrderId"}>구매주문번호</MenuItem>
                  {/*<MenuItem value={"buyerId"}>구매자 ID</MenuItem>*/}
                  <MenuItem value={"buyerName"}>구매자 이름</MenuItem>
                  <MenuItem value={"buyerPhone"}>구매자 연락처</MenuItem>
                </Select>
              </FormControl>
                <TextField
                  sx={{ width: "220px" }}
                  size={"small"}
                  value={state.searchFilterValue || ""}
                  onChange={(params) => {
                    dispatch({ type: "CHANGE_SEARCH_FILTER_VALUE", payload: params.target.value })
                  }}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") dispatch({ type: "REFRESH" })
                  }}
                />
              <Stack spacing={1} direction={"row"} sx={{ lineHeight: "45px" }}>
                <InputLabel style={{ lineHeight: "45px", marginRight: "10px", paddingLeft:"10px" }}>판매 신청일</InputLabel>
                <DesktopDatePicker
                  label="시작일"
                  inputFormat="yyyy-MM-dd"
                  value={state.startDateFilter ?? null}
                  onChange={(params) => {
                    dispatch({ type: "CHANGE_START_DATE", payload: params })
                    dispatch({ type: "REFRESH" })
                  }}
                  renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} size={"small"} />}
                />
                <DesktopDatePicker
                  label="종료일"
                  inputFormat="yyyy-MM-dd"
                  value={state.endDateFilter ?? null}
                  onChange={(params) => {
                    dispatch({ type: "CHANGE_END_DATE", payload: params })
                    dispatch({ type: "REFRESH" })
                  }}
                  renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} size={"small"} />}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Button variant={"outlined"} size={"small"} style={{ margin: "0 2px" }} onClick={handleExcelDownloadClick}>엑셀 다운로드</Button>
              <ProductTable state={state} dispatch={dispatch} fetch={fetchProducts} components={[]} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default AllProducts
