import styled from "@emotion/styled"
import { HTMLAttributes, PropsWithChildren } from "react"

type Props = {
  isOpen: boolean
  closePage?: () => void
} & HTMLAttributes<HTMLDivElement>

const PopupPage = ({ children, closePage, isOpen, ...props }: PropsWithChildren<Props>) => {
  if (!isOpen) {
    return <></>
  }

  return (
    <>
      <Overlay onClick={closePage} />
      <ChildrenWrapper {...props}>{children}</ChildrenWrapper>
    </>
  )
}

export default PopupPage

const Overlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9998;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
`

const ChildrenWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  max-width: calc(100vw - 64px);
  max-height: calc(100vh - 64px);

  box-shadow: 0px 1px 20px 2px rgba(0, 0, 0, 0.08);
  z-index: 9999;

  background: white;
  border-radius: 4px;
`
