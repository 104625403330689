import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField } from "@mui/material"
import { useState } from "react"
import { OrderType, SellOrderListSearchParams, SellOrderType } from "types/SellOrderType"
import Counters from "../Counters"
import ShippingRequestPopup from "../../../components/Popup/ShippingRequestPopup"
import ConfirmReceivingPopup from "./ConfirmReceivingPopup/ConfirmReceivingPopup"
import { DesktopDatePicker } from "@mui/lab"
import { toast } from "react-toastify"
import CreateSellOrderPopup from "../CreateSellOrderPopup"
import ViewGuaranteedSellOrderPopup from "./ViewGuaranteedSellOrderPopup"
import GuaranteedSellOrderTable from "./GuaranteedSellOrderTable"
import { useSellOrderListData } from "../useSellOrderListData"
import { getSelectedOrders, getShippings } from "pages/SellOrder/utils"
import { toDateStr } from "utils/datetimeUtil"

const searchOptionList = [
  { value: "id", label: "판매주문번호" },
  { value: "productId", label: "상품번호" },
  { value: "productTitle", label: "상품명" },
  { value: "sellerName", label: "판매자 이름" },
  { value: "sellerPhone", label: "판매자 연락처" },
  { value: "sellerId", label: "판매자ID" },
]

const RePurchaseGuarantedSellOrder = () => {
  const [isOpenDeliveryPopup, setIsOpenDeliveryPopup] = useState(false)
  const [isOpenConfirmRecievingPopup, setIsOpenConfirmRecievingPopup] = useState(false)

  const [isOpenCreatePopup, setIsOpenCreatePopup] = useState(false)
  const [isOpenDetailPopup, setIsOpenDetailPopup] = useState(false)

  const [selectedSellOrder, setSelectedSellOrder] = useState<SellOrderType | undefined>()

  const {
    isLoading,
    sellOrderCountList,
    changeSearchParams,
    sellOrderListPageData,
    selectedSellOrderIdList,
    searchParams,
    toggleSelectedOrderList,
    refreshList,
    searchObject,
    changeSearchObject,
    onSearch,
  } = useSellOrderListData([OrderType.재매입])

  const openDetailPopup = (order: SellOrderType) => {
    setSelectedSellOrder(order)
    setIsOpenDetailPopup(true)
  }

  const handlePickupClick = () => {
    setIsOpenDeliveryPopup(true)
  }

  const handleConfirmClick = () => {
    if (!!selectedSellOrderIdList.length) {
      setIsOpenConfirmRecievingPopup(true)
    } else {
      toast.error("입고요청 가능한 판매주문 건이 없습니다.")
    }
  }

  return (
    <Grid container spacing={2}>
      <CreateSellOrderPopup
        skipDiagnosis={false}
        open={isOpenCreatePopup}
        handleClose={() => {
          setIsOpenCreatePopup(false)
          refreshList()
        }}
      />
      <ViewGuaranteedSellOrderPopup
        open={isOpenDetailPopup}
        selectedSellOrder={selectedSellOrder}
        handleClose={() => setIsOpenDetailPopup(false)}
      />
      <ShippingRequestPopup
        type={"RETURN"}
        open={isOpenDeliveryPopup}
        selectedShipping={getShippings(sellOrderListPageData.content, selectedSellOrderIdList)}
        handleClose={() => {
          setIsOpenDeliveryPopup(false)
          refreshList()
        }}
      />
      <ConfirmReceivingPopup
        open={isOpenConfirmRecievingPopup}
        initOrders={getSelectedOrders(sellOrderListPageData.content, selectedSellOrderIdList)}
        handleClose={() => {
          setIsOpenConfirmRecievingPopup(false)
          refreshList()
        }}
      />
      <Grid item xs={12}>
        <Counters params={sellOrderCountList} selectedLabel={searchParams.status?.toString()} />
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
              <InputLabel style={{ lineHeight: "45px", marginRight: "10px" }}>판매 신청일</InputLabel>
              <DesktopDatePicker
                label="시작일"
                inputFormat="yyyy-MM-dd"
                value={searchParams.startDate ?? null}
                onChange={(param) => changeSearchParams("startDate", toDateStr(param || "") ?? "")}
                renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} size={"small"} />}
              />
              <DesktopDatePicker
                label="종료일"
                inputFormat="yyyy-MM-dd"
                value={searchParams.endDate ?? null}
                onChange={(param) => changeSearchParams("endDate", toDateStr(param || "") ?? "")}
                renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} size={"small"} />}
              />
          </Grid>
          <Grid item container xs={12} justifyContent="flex-end" spacing={0} style={{ marginBottom: 10 }}>
            <FormControl sx={{ width: "160px" }} size={"small"}>
              <InputLabel id="search_key">검색항목</InputLabel>
              <Select
                value={searchObject.key}
                onChange={(event) => changeSearchObject("key", event.target.value as keyof SellOrderListSearchParams)}
              >
                {searchOptionList.map((optionItem) => (
                  <MenuItem key={optionItem.value} value={optionItem.value}>
                    {optionItem.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              sx={{ width: "220px" }}
              size={"small"}
              value={searchObject.value}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onSearch()
                }
              }}
              onChange={(params) => {
                changeSearchObject("value", params.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <GuaranteedSellOrderTable
              selectedSellOrderIdList={selectedSellOrderIdList}
              toggleSelectedOrderList={toggleSelectedOrderList}
              sellOrderListPageData={sellOrderListPageData}
              isLoading={isLoading}
              openDetailPopup={openDetailPopup}
              changePage={(value) => changeSearchParams("page", value)}
              changePageSize={(value) => changeSearchParams("size", value)}
              components={[
                <Button variant={"contained"} size={"small"} style={{ margin: "0 2px" }} onClick={handlePickupClick}>
                  픽업 요청
                </Button>,
                <Button variant={"contained"} size={"small"} style={{ margin: "0 2px" }} onClick={handleConfirmClick}>
                  입고확인 및 QR 생성
                </Button>,
                <Button variant={"contained"} color={"error"} size={"small"} style={{ margin: "0 2px" }} disabled>
                  판매 포기
                </Button>,
              ]}
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default RePurchaseGuarantedSellOrder
