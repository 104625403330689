import { BuyPriceOfferType } from "../../../apis/buyPriceOfferAPI"
import PageableDataGrid from "../../../components/PageableDataGrid"
import React, { useState } from "react"
import { numberWithCommas, truncate } from "../../../utils/NumberUtils"
import { DateTime } from "luxon"
import { PageType } from "../../../types/PageType"
import BuyPriceOfferDetailPopup from "../BuyPriceOfferDetailPopup"
import { Button } from "@mui/material"


const BuyPriceOfferTable = ({ searchParams, fetch }: { searchParams: any, fetch: (params: any) => Promise<PageType<BuyPriceOfferType>> }) => {
  const [selectedProductId, setSelectedProductId] = useState<number | null>(null)

  const columns = [
    { field: "id", headerName: "제안 ID", width: 70 },
    { field: "product.id", headerName: "상품 ID", width: 80 },
    { field: "offeredUserId", headerName: "구매자 ID", width: 80 },
    { field: "offeredUserName", headerName: "구매자 이름", width: 80 },
    { field: "offeredUserPhone", headerName: "구매자 연락처", width: 120 },
    { field: "status", headerName: "상태", width: 60 },
    { field: "type", headerName: "유형", width: 60 },
    { field: "product.saleType", headerName: "판매 방식", width: 100 },
    { field: "product.status", headerName: "상품 상태", width: 100 },
    {
      field: "product.title", headerName: "상품명", flex: 1, renderCell: (params: any) => (
        <Button size={"small"} variant={"outlined"} style={{ fontWeight: "bold" }} onClick={() => {
          setSelectedProductId(params.row["product.id"])
        }}>
          {params.row["product.title"]}
        </Button>
      ),
    },
    { field: "product.detailModel.refNo", headerName: "Ref No.", width: 150 },
    { field: "productPrice", headerName: "기존 상품 가격", width: 110, valueGetter: (params: any) => `${numberWithCommas(truncate(params.row.productPrice, 4))} 만원`, type: "number" },
    { field: "offeredPrice", headerName: "제안 가격", width: 110, valueGetter: (params: any) => `${numberWithCommas(truncate(params.row.offeredPrice, 4))} 만원`, type: "number" },
    { field: "product.price", headerName: "현재 가격", width: 110, valueGetter: (params: any) => `${numberWithCommas(truncate(params.row["product.price"], 4))} 만원`, type: "number" },
    { field: "offeredAt", headerName: "가격 제안일", width: 120, valueGetter: (params: any) => `${DateTime.fromISO(params.row.offeredAt).toFormat("yyyy-MM-dd")}`, type: "date" },
    { field: "biddingAvailableDate", headerName: "제안 마감일", width: 100 },
  ]

  return (
    <>
      <PageableDataGrid
        columns={columns}
        fetch={fetch}
        searchParams={searchParams}
      />
      {selectedProductId && <BuyPriceOfferDetailPopup productId={selectedProductId} handleClose={() => setSelectedProductId(null)} />}
    </>
  )
}

export default BuyPriceOfferTable