import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField } from "@mui/material"
import { useEffect, useReducer } from "react"
import { useSearchParams } from "react-router-dom"
import ConsignmentConsignmentSellOrderTable from "./ConsignmentSellOrderTable"
import {countSellOrders, getSellOrders, cancelSellOrder, deniedSellOrderShipping, getSellOrderList} from "../../../apis/sellOrderAPI"
import { OrderType, SellOrderListSearchParams, SellOrderStatusCode, SellOrderType } from "types/SellOrderType"
import { PageType } from "types/PageType"
import { CountType } from "types/CountType"
import Counters from "../Counters"
import { toApplyCounts } from "../../../utils/SellOrderTypeConverter"
import ShippingRequestPopup from "../../../components/Popup/ShippingRequestPopup"
import ConfirmReceivingPopup from "./ConfirmReceivingPopup/ConfirmReceivingPopup"
import { DesktopDatePicker } from "@mui/lab"
import { DateTime } from "luxon"
import { toast } from "react-toastify"
import CreateSellOrderPopup from "../CreateSellOrderPopup"
import ViewConsignmentConsignmentSellOrderPopup from "./ViewConsignmentSellOrderPopup"
import { getShippings } from "../utils"
import {revertShipping} from "../../../apis/shippingAPI";
import {downloadExcel} from "../../../utils/excelUtil";
import { toDateTimeStr } from "../../../utils/datetimeUtil"

export interface SellOrderState {
  selectedIds: string[]
  sellOrders: PageType<SellOrderType>
  sellOrderCounts: CountType[]
  isCreatePopup: boolean
  isViewPopup: boolean
  isShippingRequestPopupOpen: boolean
  isConfirmReceivingPopupOpen: boolean
  isOrderQRPopupOpen: boolean
  editSellOrder?: SellOrderType
  startDateFilter: DateTime | null
  endDateFilter: DateTime | null
  searchFilterKey: string | null
  searchFilterValue: string | null
  refresh: boolean
  loading: boolean
}

const initState: (orderId?: string | null) => SellOrderState = (orderId) => {
  let state: SellOrderState = {
    selectedIds: [],
    sellOrders: {
      content: [],
      pageable: {
        pageSize: 25,
        pageNumber: 0,
      },
      totalPages: 0,
      totalElements: 0,
      numberOfElements: 0,
    },
    sellOrderCounts: [],
    isCreatePopup: false,
    isViewPopup: false,
    isShippingRequestPopupOpen: false,
    isConfirmReceivingPopupOpen: false,
    isOrderQRPopupOpen: false,
    editSellOrder: undefined,
    startDateFilter: null,
    endDateFilter: null,
    searchFilterKey: "productId",
    searchFilterValue: null,
    refresh: false,
    loading: false,
  }

  if (orderId) {
    state.searchFilterKey = "id"
    state.searchFilterValue = orderId
  }

  return state
}

interface SellOrderAction {
  type: string
  payload?: any
}

function reducer(state: SellOrderState, action: SellOrderAction): SellOrderState {
  switch (action.type) {
    case "SELECTION_SELLORDER_IDS":
      return { ...state, selectedIds: action.payload }
    case "FETCH_SELLORDER":
      return { ...state, sellOrders: action.payload }
    case "FETCH_SELLORDER_COUNT":
      return { ...state, sellOrderCounts: action.payload }
    // case 'SHOW_EDIT_SELLORDER_POPUP': return {...state,  isShowEditPopup: true, editSellOrder: action.payload}
    // case 'HIDE_EDIT_SELLORDER_POPUP': return {...state,  isShowEditPopup: false}
    case "SHOW_CREATE_POPUP_OPEN":
      return { ...state, isCreatePopup: true }
    case "HIDE_CREATE_POPUP_OPEN":
      return { ...state, isCreatePopup: false }
    case "SHOW_VIEW_POPUP_OPEN":
      return { ...state, isViewPopup: true, editSellOrder: action.payload }
    case "HIDE_VIEW_POPUP_OPEN":
      return { ...state, isViewPopup: false }
    case "SHOW_DELIVERY_REQUEST_POPUP_OPEN":
      return { ...state, isShippingRequestPopupOpen: true }
    case "HIDE_DELIVERY_REQUEST_POPUP_OPEN":
      return { ...state, isShippingRequestPopupOpen: false }
    case "SHOW_CONFIRM_RECEIVING_POPUP_OPEN":
      return { ...state, isConfirmReceivingPopupOpen: true }
    case "HIDE_CONFIRM_RECEIVING_POPUP_OPEN":
      return { ...state, isConfirmReceivingPopupOpen: false }
    case "SHOW_ORDER_QR_POPUP_OPEN":
      return { ...state, isOrderQRPopupOpen: true }
    case "HIDE_ORDER_QR_POPUP_OPEN":
      return { ...state, isOrderQRPopupOpen: false }
    case "CHANGE_START_DATE":
      return { ...state, startDateFilter: action.payload, refresh: !state.refresh }
    case "CHANGE_END_DATE":
      return { ...state, endDateFilter: action.payload, refresh: !state.refresh }
    case "CHANGE_SEARCH_FILTER_KEY":
      return { ...state, searchFilterKey: action.payload, searchFilterValue: "" }
    case "CHANGE_SEARCH_FILTER_VALUE":
      return { ...state, searchFilterValue: action.payload }
    case "SEARCH":
      return { ...state, refresh: !state.refresh }
    case "REFRESH":
      return { ...state, refresh: !state.refresh }
    case "START_LOADING":
      return { ...state, loading: true }
    case "END_LOADING":
      return { ...state, loading: false }
  }

  return state
}

function getSelectedOrders(state: SellOrderState, types?: SellOrderStatusCode[]): SellOrderType[] {
  if (types)
    return state.sellOrders.content.filter(
      (it) => state.selectedIds.includes(it.id!) && types.includes(it.sellOrderStatus!!)
    )
  else return state.sellOrders.content.filter((it) => state.selectedIds.includes(it.id!))
}

const ConsignmentSellOrder = () => {
  const [currentSearchParams] = useSearchParams()
  const [state, dispatch] = useReducer(reducer, initState(currentSearchParams.get("orderId")))

  const fetchSellOrders = (searchParams: SellOrderListSearchParams = {}) => {
    let newParams: SellOrderListSearchParams = {
      ...searchParams,
      status: (currentSearchParams.get("status")?.split(",") as SellOrderStatusCode[]) ?? undefined,
      sellerId: currentSearchParams.get("sellerId") ?? undefined,
      sellOrderType: [OrderType.위탁, OrderType.직매입],
      startDate: state.startDateFilter?.toISODate(),
      endDate: state.endDateFilter?.toISODate(),
    }
    if (state.searchFilterKey && state.searchFilterValue)
      newParams = { ...newParams, [state.searchFilterKey]: state.searchFilterValue }

    dispatch({ type: "START_LOADING" })
    getSellOrders(newParams)
      .then((sellOrders) => dispatch({ type: "FETCH_SELLORDER", payload: sellOrders }))
      .finally(() => dispatch({ type: "END_LOADING" }))
  }

  const fetchSellOrderCounts = async (searchParams: any = {}) => {
    const result = await countSellOrders({
      ...searchParams,
      sellerId: currentSearchParams.get("sellerId") ?? undefined,
      status: (currentSearchParams.get("status")?.split(",") as SellOrderStatusCode[]) ?? undefined,
      sellOrderType: [OrderType.위탁, OrderType.직매입],
      startDate: state.startDateFilter && state.startDateFilter.toISODate(),
      endDate: state.endDateFilter && state.endDateFilter.toISODate(),
    })
    dispatch({
      type: "FETCH_SELLORDER_COUNT",
      payload: toApplyCounts(
        result,
        [
          SellOrderStatusCode.픽업_준비,
          SellOrderStatusCode.입고중,
          SellOrderStatusCode.입고_완료,
          SellOrderStatusCode.진단중,
        ],
        ".",
        [],
        true
      ),
    })
  }

  useEffect(() => {
    fetchSellOrders({
      page: state.sellOrders.pageable?.pageNumber || 0,
      size: state.sellOrders.pageable?.pageSize || 25,
    })
    fetchSellOrderCounts()
  }, [state.refresh, currentSearchParams])

  const handleExcelDownloadClick = () => {
    if (state.startDateFilter === null) {
      toast.error("시작일을 선택해주세요")
      return false
    }
    if (state.endDateFilter === null) {
      toast.error("종료일을 선택해주세요")
      return false
    }

    let newParams: SellOrderListSearchParams = {
      status: (currentSearchParams.get("status")?.split(",") as SellOrderStatusCode[]) ?? undefined,
      sellerId: currentSearchParams.get("sellerId") ?? undefined,
      sellOrderType: [OrderType.위탁, OrderType.직매입],
      startDate: state.startDateFilter?.toISODate(),
      endDate: state.endDateFilter?.toISODate()
    }

    if (state.searchFilterKey && state.searchFilterValue)
      newParams = { ...newParams, [state.searchFilterKey]: state.searchFilterValue }

    getSellOrderList(newParams)
        .then((sellOrders) => {
          if ( sellOrders.length == 0) {
            toast.error("다운로드 할 내역이 없습니다.")
            return false
          }
          let data = sellOrders.map(it => {
            return {
              "판매주문번호": it.id,
              "상품번호": it?.product?.id,
              "진행상태": it.sellOrderStatus,
              "제품상태": it.product?.exposedProductInfo?.productCondition,
              "스탬핑": it.product?.exposedProductInfo?.stampingDate != null ? it.product.exposedProductInfo.stampingDate : it.product?.exposedProductInfo?.stampingYear,
              "입고방식": it.shipping?.shippingMethod?.replace('안전배송_2', '일양택배')?.replace('안전배송', '발렉스'),
              "입고상태": it.shipping?.shippingStatus,
              "전시판매": it.exhibitionSale ? '동의' : '미동의',
              "판매자ID": it.seller?.id,
              "상품명": `${it?.product?.title} ${it?.product?.titleSecondary}`,
              "Ref No.": it?.product?.detailModel?.fullRefNo,
              "판매희망가": it?.product?.price,
              "판매신청일": toDateTimeStr(it?.orderedAt),
              "픽업요청일": toDateTimeStr(it?.deliveryRequestedAt),
              "입고확인일": toDateTimeStr(it?.waitingDiagnosisAt)
            }
          })
          downloadExcel(data, "판매관리_위탁직매입")
        })
  }

  const handlePickupClick = () => {
    if (state.selectedIds.length == 0) {
      toast.error("선택한 내역이 없습니다.")
      return
    }
    dispatch({ type: "SHOW_DELIVERY_REQUEST_POPUP_OPEN" })
  }
  const handleRevertClick = () => {
    if (state.selectedIds.length == 0) {
      toast.error("선택한 내역이 없습니다.")
      return
    }
    if (window.confirm(`선택된 ${state.selectedIds.length}건의 입고중 상태를 픽업 준비 단계로 되돌립니다.\n배송업체에 이미 픽업 요청이 전달된 경우 필해 취소처리 바랍니다.`))
      revertShipping({
        items: getSelectedOrders(state, [SellOrderStatusCode.입고중]).map(it => {
          return {id: it.shipping?.id}
        }),
        manually: true
      })
          .then((res) => {
            toast.success(`${res.succeed?.length}건의 배송 상태가 변경되었습니다.`)
          })
          .catch((err) => {
            toast.error(`픽업 요청 취소가 실패했습니다. (${err})`)
          }).finally(() => dispatch({type: "REFRESH"}))
  }
  const handleConfirmClick = () => {
    if (state.selectedIds.length > 0) {
      dispatch({ type: "SHOW_CONFIRM_RECEIVING_POPUP_OPEN" })
    } else {
      toast.error("선택한 내역이 없습니다.")
    }
  }
  const handleCancelClick = () => {
    if (state.selectedIds.length == 0) {
      toast.error("선택한 내역이 없습니다.")
      return
    }
    if(window.confirm(
      "선택한 판매주문을 판매포기 처리하시겠습니까?"
    )) {
      Promise.all(state.selectedIds.map((id) => cancelSellOrder(id))).then(() => {
        dispatch({ type: "REFRESH" })
        alert(`판매포기 처리하였습니다.`)
      })
        .catch((err) => {
          if ( err.message == undefined) {
            toast.error('에러가 발생하였습니다.')
          }
          else {
            toast.error(err.message)
            console.log("Error", err)
          }
        })
    }
    // toast.error("TODO: 판매포기")
  }

  const handleDeniedSellOrderShipping = () => {
    if (state.selectedIds.length == 0) {
      toast.error("선택한 내역이 없습니다.")
      return
    }
    if (window.confirm("판매자가 입고 거부하여 배송 상태와 상관없이 페널티가 1.8% 가 부여됩니다.\n[판매자 입고 거부] 처리하시겠습니까?")) {
      Promise.all(state.selectedIds.map((id) => deniedSellOrderShipping(id))).then(() => {
        dispatch({ type: "REFRESH" })
        alert(`[판매자 입고 거부] 처리가 완료되었습니다.`)
      })
        .catch((err) => {
          if ( err.message == undefined) {
            toast.error('에러가 발생하였습니다.')
          }
          else {
            toast.error(err.message)
            console.log("Error", err)
          }
        })
    }
  }

  return (
    <Grid container spacing={2}>
      <CreateSellOrderPopup
        skipDiagnosis={false}
        open={state.isCreatePopup}
        handleClose={() => {
          dispatch({ type: "HIDE_CREATE_POPUP_OPEN" })
          dispatch({ type: "REFRESH" })
        }}
      />
      <ViewConsignmentConsignmentSellOrderPopup
        open={state.isViewPopup}
        selectedSellOrderId={state.editSellOrder?.id}
        handleClose={() => {
          dispatch({ type: "HIDE_VIEW_POPUP_OPEN" })
          dispatch({ type: "REFRESH" })
        }}
      />
      <ShippingRequestPopup
        type={"RETURN"}
        open={state.isShippingRequestPopupOpen}
        selectedShipping={getShippings(state.sellOrders.content, state.selectedIds)}
        handleClose={() => {
          dispatch({ type: "HIDE_DELIVERY_REQUEST_POPUP_OPEN" })
          dispatch({ type: "REFRESH" })
        }}
      />
      <ConfirmReceivingPopup
        open={state.isConfirmReceivingPopupOpen}
        initOrders={getSelectedOrders(state)}
        handleClose={() => {
          dispatch({ type: "HIDE_CONFIRM_RECEIVING_POPUP_OPEN" })
          dispatch({ type: "REFRESH" })
        }}
      />
      <Grid item xs={12}>
        <Counters params={state.sellOrderCounts} selectedLabel={currentSearchParams.get("status")} />
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <Grid item container xs={12} justifyContent="flex-end" spacing={1}>
              <InputLabel style={{ lineHeight: "45px", marginRight: "10px" }}>판매 신청일</InputLabel>
              <DesktopDatePicker
                label="시작일"
                inputFormat="yyyy-MM-dd"
                value={state.startDateFilter}
                onChange={(params) => {
                  dispatch({ type: "CHANGE_START_DATE", payload: params })
                }}
                renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} size={"small"} />}
              />
              <DesktopDatePicker
                label="종료일"
                inputFormat="yyyy-MM-dd"
                value={state.endDateFilter}
                onChange={(params) => {
                  dispatch({ type: "CHANGE_END_DATE", payload: params })
                }}
                renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} size={"small"} />}
              />
          </Grid>
          <Grid item container xs={12} justifyContent="flex-end" spacing={0}>
            <FormControl sx={{ width: "160px" }} size={"small"}>
              <InputLabel id="search_key">검색항목</InputLabel>
              <Select
                value={state.searchFilterKey || ""}
                onChange={(params) => {
                  dispatch({ type: "CHANGE_SEARCH_FILTER_KEY", payload: params.target.value })
                }}
              >
                <MenuItem value={"id"}>판매주문번호</MenuItem>
                <MenuItem value={"productId"}>상품번호</MenuItem>
                <MenuItem value={"productTitle"}>상품명</MenuItem>
                <MenuItem value={"shippingMethod"}>입고방식</MenuItem>
                <MenuItem value={"sellerName"}>판매자 이름</MenuItem>
                <MenuItem value={"sellerPhone"}>판매자 연락처</MenuItem>
                <MenuItem value={"sellerId"}>판매자ID</MenuItem>
              </Select>
            </FormControl>
            <TextField
              sx={{ width: "220px", marginBottom: "10px" }}
              size={"small"}
              value={state.searchFilterValue || ""}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch({ type: "SEARCH" })
                }
              }}
              onChange={(params) => {
                dispatch({ type: "CHANGE_SEARCH_FILTER_VALUE", payload: params.target.value })
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ConsignmentConsignmentSellOrderTable
              state={state}
              dispatch={dispatch}
              fetch={fetchSellOrders}
              components={[
                <Button variant={"outlined"} size={"small"} style={{ margin: "0 2px" }} onClick={handleExcelDownloadClick}>
                  엑셀 다운로드
                </Button>,
                <Button variant={"contained"} size={"small"} style={{ margin: "0 2px" }} onClick={handlePickupClick}>
                  픽업 요청
                </Button>,
                <Button variant={"contained"} size={"small"} style={{ margin: "0 2px" }} onClick={handleRevertClick}>
                  픽업 요청 취소
                </Button>,
                <Button variant={"contained"} size={"small"} style={{ margin: "0 2px" }} onClick={handleConfirmClick}>
                  입고확인 및 QR 생성
                </Button>,
                <Button
                  variant={"contained"}
                  color={"error"}
                  size={"small"}
                  style={{ margin: "0 2px" }}
                  onClick={handleCancelClick}
                >
                  판매 포기
                </Button>,
                <Button
                    variant={"contained"}
                    color={"error"}
                    size={"small"}
                    style={{ margin: "0 2px" }}
                    onClick={handleDeniedSellOrderShipping}
                >
                  판매자 입고 거부
                </Button>,
              ]}
            />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default ConsignmentSellOrder
