import PopupPage from "../../../components/Popup/PopupPage";
import PopupPageTitle from "../../../components/Popup/PopupPage/PopupPageTitle";
import PopupPageFooter from "../../../components/Popup/PopupPage/PopupPageFooter";
import Button from "../../../components/Button";
import PopupPageScrollContainer from "../../../components/Popup/PopupPage/PopupPageScrollContainer";
import styled from "@emotion/styled"
import React, {useState} from "react";
import {useMount} from "react-use";
import {BuyPriceOfferGroupByProductType, getBuyPriceOfferGroupByProductPage, getBuyPriceOfferPage} from "../../../apis/buyPriceOfferAPI";
import {Box, Stack} from "@mui/material";
import {getProductPriceHistories} from "../../../apis/productAPI";
import PageableDataGrid from "../../../components/PageableDataGrid";
import {numberWithCommas, truncate} from "../../../utils/NumberUtils";
import {DateTime} from "luxon";

const getPriceHistoryColumns = () => [
    {field: 'id', headerName: "이력 ID", width: 70},
    {field: 'createdAt', headerName: "변경일", width: 120, valueGetter: (params: any) => `${DateTime.fromISO(params.row.createdAt).toFormat("yyyy-MM-dd")}`, type: 'date'},
    {field: 'estimatedResellPrice.correctedEstimatedPrice.correctedEstimatedPrice', headerName: "최적판매가", flex: 1, valueGetter: (params: any) => `${numberWithCommas(truncate(params.row['estimatedResellPrice.correctedEstimatedPrice.correctedEstimatedPrice'], 4))} 만원`, type: 'number'},
    {field: 'beforePrice', headerName: "변경전가격", flex: 1, valueGetter: (params: any) => `${numberWithCommas(truncate(params.row.beforePrice, 4))} 만원`, type: 'number'},
    {field: 'afterPrice', headerName: "변경후가격", flex: 1, valueGetter: (params: any) => `${numberWithCommas(truncate(params.row.afterPrice, 4))} 만원`, type: 'number'},
]

const getBuyPriceOfferColumns = () => [
    {field: 'id', headerName: "제안 ID", width: 70},
    {field: 'offeredUserId', headerName: "제안자 ID", width: 80},
    {field: 'status', headerName: "상태", width: 60},
    {field: 'type', headerName: "유형", width: 60},
    {field: 'offeredAt', headerName: "가격 제안일", width: 120, valueGetter: (params: any) => `${DateTime.fromISO(params.row.offeredAt).toFormat("yyyy-MM-dd")}`, type: 'date'},
    {field: 'biddingAvailableDate', headerName: "제안 마감일", width: 100},
    {field: 'productPrice', headerName: "기존 상품 가격", flex: 1, valueGetter: (params: any) => `${numberWithCommas(truncate(params.row.productPrice, 4))} 만원`, type: 'number'},
    {field: 'offeredPrice', headerName: "제안 가격", flex: 1, valueGetter: (params: any) => `${numberWithCommas(truncate(params.row.offeredPrice, 4))} 만원`, type: 'number'},
]


const BuyPriceOfferDetailPopup =  ({productId, handleClose}: {productId?: number|null, handleClose: () => void}) => {
    const [buyPriceOfferGroupByProduct, setBuyPriceOfferGroupByProduct] = useState<BuyPriceOfferGroupByProductType|null>(null)

    useMount(() => {
        getBuyPriceOfferGroupByProductPage({productId: productId}).then(({content, } ) => setBuyPriceOfferGroupByProduct(content?.[0]))
    })

    return (
        <PopupPage isOpen style={{ width: "800px" }}>
            <ContentWrapper>
                <PopupPageTitle>가격 제안 정보</PopupPageTitle>
                <PopupPageScrollContainer>
                    <Stack spacing={5}>
                        <table className={"basic"}>
                            <tbody>
                            <tr>
                                <td className={"ta-center"} rowSpan={6}>
                                    <img
                                        style={{ display: "block", margin: "auto", width: "140px", height: "300px", objectFit: "cover" }}
                                        src={buyPriceOfferGroupByProduct?.thumbnail}
                                        alt=""
                                    />
                                </td>
                                <td className={"header"} colSpan={3}>
                                    상품명
                                </td>
                                <td className={"header"} colSpan={1}>
                                    Ref No.
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={1}> {buyPriceOfferGroupByProduct?.brandSid}</td>
                                <td colSpan={1}>{buyPriceOfferGroupByProduct?.title}</td>
                                <td colSpan={1}>{buyPriceOfferGroupByProduct?.titleSecondary}</td>
                                <td colSpan={1}>{buyPriceOfferGroupByProduct?.detailModel?.fullRefNo}</td>
                            </tr>
                            <tr>
                                <td className={"header"} colSpan={1}>상태</td>
                                <td className={"header"} colSpan={1}>판매 유형</td>
                                <td className={"header"} colSpan={1}>상품 가격</td>
                                <td className={"header"} colSpan={1}>가격 변경일</td>
                            </tr>
                            <tr>
                                <td colSpan={1}>{buyPriceOfferGroupByProduct?.status}</td>
                                <td colSpan={1}>{buyPriceOfferGroupByProduct?.saleType}</td>
                                <td colSpan={1}>{numberWithCommas(truncate(buyPriceOfferGroupByProduct?.price, 4))} 만원</td>
                                <td colSpan={1}>{buyPriceOfferGroupByProduct?.priceUpdatedAt ? DateTime.fromISO(buyPriceOfferGroupByProduct?.priceUpdatedAt).toFormat("yyyy-MM-dd") : '-'}</td>
                            </tr>

                            <tr>
                                <td className={"header"} colSpan={1}>제안자 구매 여부</td>
                                <td className={"header"} colSpan={1}>최종 가격 제안일</td>
                                <td className={"header"} colSpan={1}>결제 완료일</td>
                            </tr>
                            <tr>
                                <td colSpan={1}>{buyPriceOfferGroupByProduct?.isOfferedUserBought == true ? 'O' : 'X'}</td>
                                <td colSpan={1}>{buyPriceOfferGroupByProduct?.offeredUserBoughtAt}</td>
                                <td colSpan={1}>{buyPriceOfferGroupByProduct?.transactionAt ? DateTime.fromISO(buyPriceOfferGroupByProduct?.transactionAt).toFormat("yyyy-MM-dd") : ''}</td>

                            </tr>


                            </tbody>
                        </table>
                        <Stack spacing={0.5}>
                            <h2>가격 변경 이력</h2>
                            <Box sx={{height: 400, width: '100%'}}>
                                <PageableDataGrid columns={getPriceHistoryColumns()} fetch={getProductPriceHistories} searchParams={{productId}}/>
                            </Box>
                        </Stack>
                        <Stack spacing={0.5}>
                            <h2>가격 제안 이력</h2>
                            <Box sx={{height: 400, width: '100%'}}>
                                <PageableDataGrid columns={getBuyPriceOfferColumns()} searchParams={{productId}} fetch={getBuyPriceOfferPage} />
                            </Box>
                        </Stack>
                    </Stack>
                </PopupPageScrollContainer>
                <PopupPageFooter>
                    <Button buttonStyle="black-border" onClick={handleClose}>
                        닫기
                    </Button>
                </PopupPageFooter>
            </ContentWrapper>
        </PopupPage>
    )
}

export default BuyPriceOfferDetailPopup


const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  padding: 16px;
`